import { Component, OnInit } from '@angular/core';
import { SocketService } from './socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'Simple Chatbot';
  messageArray = [];
  synth: any;
  voices: any;
  isMobile: boolean = false;
  isbeginer: boolean = false;
  replyDisable: boolean = true;
  selectedNation: string = "";
  constructor(private socketService: SocketService,
  ) {
    this.synth = window.speechSynthesis;
    this.voices = this.synth.getVoices();
  }
  message = '';

  ngOnInit() {  
    // this.messageArray.push({ name: 'bot', message: 'السلام عليكم' });
    this.messageArray.push({ name: 'bot', message: 'ഹബീബി, നിങ്ങളുടെ നാഷനൽ സെലക്ട് ചെയ്യുക.' });
    // this.messageArray.push({ name: 'bot ', message: 'Type your queries in malayalam' });
    //this.messageArray.push({ name: 'bot', message: 'Select your Nation' });
    //  this.messageArray.push({ name: 'select', message: "<div class='row'><div class='col-3 col-md-4'><button type='button1 class='btn btn-primary'>SAUDI EAST</button></div><div class='col-3 col-md-4'>Hellow</div></div>" });
    this.messageArray.push({ name: 'selectNation', message: "" });
    this.socketService.receivedReply().subscribe(data => {
      this.messageArray.push({ name: 'bot', message: data.outputMessage });
      //this.speak(data.outputMessage);   // enable this to spleak

     
    });


    if (window.screen.width >= 640) {
      this.isMobile = false;
    }
    else {
      this.isMobile = true;
    }

  }

  onFeedbackClick(){
    window.open("http://campaign.rsconline.org/Feedbacks.aspx", "_blank");
  }

  onRscClick(){
    window.open("http://rsconline.org/", "_blank");
  }

  onClickNation(nation: string, id: number) { 
    this.replyDisable = false;
    this.selectedNation = nation;
    this.socketService.sendMessage({ message: "nation:" + nation });
  }

  sendMessage() {
    const data = { message: this.message.replace(/\?/g, "").replace(/\./g, ' ').trim().toLocaleLowerCase() };
    this.socketService.sendMessage(data);
    this.messageArray.push({ name: 'you', message: this.message });
    this.message = '';
    this.scrollToBottom();
  }

  scrollToBottom() {
    var objDiv = document.getElementById("mainDiv");
    objDiv.scrollTop = objDiv.scrollHeight;
  }

  speak(string) {
    let u = new SpeechSynthesisUtterance(string);
    u.text = string;
    u.lang = "en-US";
    // u.lang = "ml";
    u.volume = 1; //0-1 interval
    u.rate = 1;
    // u.voice =  {default:false, voiceURI:"Samantha", name:"Samantha", lang: "en-US", localService:true},
    u.pitch = 1; //0-2 interval
    this.synth.speak(u);
  }

  speakGoogle(string) {

    const url = 'https://texttospeech.googleapis.com/v1beta1/text:synthesize?key=GOOGLE_API_KEY'
    const data = {
      'input': {
        'text': 'Android is a mobile operating system developed by Google, based on the Linux kernel and designed primarily for touchscreen mobile devices such as smartphones and tablets.'
      },
      'voice': {
        'languageCode': 'en-gb',
        'name': 'en-GB-Standard-A',
        'ssmlGender': 'FEMALE'
      },
      'audioConfig': {
        'audioEncoding': 'MP3'
      }
    };
    const otherparam = {
      headers: {
        "content-type": "application/json; charset=UTF-8"
      },
      body: JSON.stringify(data),
      method: "POST"
    };
    fetch(url, otherparam)
      .then(data => { return data.json() })
      .then(res => { console.log(res.audioContent); })
    // .catch(error=>{console.log(error);state.onError(error)})
  }

  public onComplete() {
    this.scrollToBottom();
  }
}
